<template>
  <div class="c-app flex-row align-items-center login-page">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6" style="max-width: 500px;">
          <img src="https://clnb.ru/dist/images/logo.svg" alt="" class="login-logo">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <v-form @submit="logIn">
                  <h1>CRM {{ city.city }}</h1>
                  <v-text-field
                      class="mb-2"
                      outlined
                      placeholder="Логин"
                      type="text"
                      autocomplete="username email"
                      v-model="login"
                  />
                  <v-text-field
                      class="mb-2"
                      outlined
                      v-model="password"
                      placeholder="Пароль"
                      :type="showPass ? 'text' : 'password'"
                      autocomplete="current-password"
                      :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPass = !showPass"
                  />
                  <v-row class="pb-3">
                    <v-checkbox v-model="office_computer" :true-value="1" :false-value="0" label="Офисный компьютер"/>
                  </v-row>
                  <v-row>
                    <v-btn :loading="loading" class="px-4" @click="logIn">Войти</v-btn>
                  </v-row>
                </v-form>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
      <flash-message transitionIn="animated swing" class="flashpool"></flash-message>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      city: {},
      login: null,
      password: null,
      office_computer: 0,
      showPass: false
    }
  },
  methods: {
    logIn() {
      this.$store.state.server.request('auth/login', {login: this.login, password: this.password,
        office_computer: this.office_computer}, (data) => {
        localStorage.setItem('office_computer', this.office_computer);
        this.$store.state.server.setToken(data.response.token);
        window.location.reload();
      }, (data) => {
        console.log(data)
        if (data && data.error) this.$root.notify(data.error, 'error');
      });
    }
  },
  created() {
    this.$store.state.server.request('auth/city', {}, (data) => {
      this.city = data.response;
    }, () => {
      this.city = 'Город не найден!'
    })
  },
  mounted() {
    this.office_computer = +localStorage.getItem('office_computer');
  }
}
</script>
